import React, { useState } from 'react';
import Ruleta from './Ruleta'; // Asegúrate de que la ruta de importación sea correcta

const Inicio: React.FC = () => {
  const [mostrarRuleta, setMostrarRuleta] = useState(false);

  const comenzarAventura = () => {
    setMostrarRuleta(true);
  };

  if (mostrarRuleta) {
    return <Ruleta />;
  }

  return (
    <div className="inicio-container">
      <h1>Bienvenido a Nuestra Aplicación</h1>
      
      <section className="hero">
        <h2>Descubre el Mundo de la Rueta</h2>
        <p>Esta Ruleta esta hecha para el sorteo de los items dropeados por el World Boss.</p>
        <button className="cta-button" onClick={comenzarAventura}>Comienza el sorteo</button>
      </section>

      <section className="features">
        <h3>Características Principales</h3>
        <ul>
          <li>Planifica tus rutas con facilidad</li>
          <li>Comparte tus experiencias con la comunidad</li>
          <li>Descubre nuevos destinos emocionantes</li>
          <li>Conecta con otros viajeros</li>
        </ul>
      </section>

      <section className="recent-activity">
        <h3>Actividad Reciente</h3>
        <div className="activity-feed">
          <div className="activity-item">
            <p><strong>María</strong> compartió una nueva ruta en Barcelona</p>
          </div>
          <div className="activity-item">
            <p><strong>Carlos</strong> completó su viaje a los Pirineos</p>
          </div>
          <div className="activity-item">
            <p><strong>Ana</strong> publicó fotos de su aventura en Sevilla</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Inicio;
