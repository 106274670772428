  
import React, { useState, useCallback, useMemo } from 'react';
import './Build.css';
interface WeaponMap {
  [key: string]: {
    name: string;
    skills: string[];
  };
}

interface Trait {
    id: string;
    name: string;
    description: string;
  }
  
  interface Skill {
    id: string;
    name: string;
    description: string;
    image: string;
    level: number;
    maxLevel: number;
    category: 'active' | 'passive' | 'defensive';
    traits: Trait[];
  }
  
  interface BuildData {
    name: string;
    mainHand: string;
    offHand: string;
    tags: string[];
    active: SkillData[];
    passive: SkillData[];
    defensive: SkillData[];
    description: string;
  }
  
  interface SkillData {
    skillId: string;
    lvl: number;
    order: number;
    traits?: string[];
  }

const Build: React.FC = () => {
  const [buildData, setBuildData] = useState<BuildData>({
    name: "PVP",
    mainHand: "sword",
    offHand: "sword2h",
    tags: ["pvp-1v1"],
    active: [
      { skillId: "SkillSet_WP_SW_SH_S_DebuffAttack", lvl: 15, order: 0, traits: ["SkillSet_WP_SW_SH_S_DebuffAttack_trait_1"] },
      { skillId: "SkillSet_WP_SW_SH_S_PullSkill", lvl: 15, order: 1, traits: ["SkillSet_WP_SW_SH_S_PullSkill_trait_2"] },
      { skillId: "SkillSet_WP_SW2_S_StunAttack", lvl: 15, order: 2, traits: ["SkillSet_WP_SW2_S_StunAttack_trait_1","SkillSet_WP_SW2_S_StunAttack_trait_2","SkillSet_WP_SW2_S_StunAttack_trait_4","SkillSet_WP_SW2_S_StunAttack_trait_5"] },
      { skillId: "SkillSet_WP_SW2_S_UpperAttack", lvl: 15, order: 3, traits: ["SkillSet_WP_SW2_S_UpperAttack_trait_2","SkillSet_WP_SW2_S_UpperAttack_trait_4"] },
      { skillId: "SkillSet_WP_SW2_S_CounterAttack", lvl: 15, order: 4, traits: ["SkillSet_WP_SW2_S_CounterAttack_trait_2","SkillSet_WP_SW2_S_CounterAttack_trait_3"] },
      { skillId: "SkillSet_WP_SW2_S_PowerAttack", lvl: 15, order: 5, traits: ["SkillSet_WP_SW2_S_PowerAttack_trait_2","SkillSet_WP_SW2_S_PowerAttack_trait_3"] },
      { skillId: "SkillSet_WP_SW2_S_GaiaCrash", lvl: 15, order: 6, traits: ["SkillSet_WP_SW2_S_GaiaCrash_trait_3"] },
      { skillId: "SkillSet_WP_SW2_S_LeapAttack", lvl: 15, order: 7, traits: ["SkillSet_WP_SW2_S_LeapAttack_trait_1","SkillSet_WP_SW2_S_LeapAttack_trait_2"] },
      { skillId: "SkillSet_WP_SW_SH_S_WhirlwindSlash", lvl: 15, order: 8, traits: ["SkillSet_WP_SW_SH_S_WhirlwindSlash_trait_2","SkillSet_WP_SW_SH_S_WhirlwindSlash_trait_3"] },
      { skillId: "SkillSet_WP_SW_SH_S_ShieldBuff", lvl: 15, order: 9 },
      { skillId: "SkillSet_WP_SW2_S_ShieldBuff", lvl: 15, order: 10, traits: ["SkillSet_WP_SW2_S_ShieldBuff_trait_2","SkillSet_WP_SW2_S_ShieldBuff_trait_3","SkillSet_WP_SW2_S_ShieldBuff_trait_4"] },
      { skillId: "SkillSet_WP_SW_SH_S_BuffAttack", lvl: 15, order: 11 }
    ],
    passive: [
      { skillId: "SkillSet_WP_SW2_S_MaxHPUp", lvl: 15, order: 0 },
      { skillId: "SkillSet_WP_SW2_S_CurrentHpBuff", lvl: 15, order: 1 },
      { skillId: "SkillSet_WP_SW_SH_S_ArmorUp", lvl: 15, order: 2 },
      { skillId: "SkillSet_WP_SW_SH_S_BlockSuccecsToleranceUp", lvl: 15, order: 3 },
      { skillId: "SkillSet_WP_SW2_S_StunTargetCritical", lvl: 15, order: 4 },
      { skillId: "SkillSet_WP_SW2_S_HittedBuff", lvl: 15, order: 5 },
      { skillId: "SkillSet_WP_SW_SH_S_AroundCountBuff", lvl: 15, order: 6 },
      { skillId: "SkillSet_WP_SW_SH_S_TheCloserEvasionUP", lvl: 15, order: 7 }
    ],
    defensive: [
      { skillId: "SkillSet_WP_SW_SH_S_CounterMove", lvl: 15, order: 0 }
    ],
    description: "<p>PVP 1 VS 1</p>"
  });

  const [skills, setSkills] = useState<Skill[]>([
    {
      id: "SkillSet_WP_SW_SH_S_DebuffAttack",
      name: "Debuff Attack",
      description: "Attacks the enemy and applies a debuff",
      image: "path/to/debuff_attack.png",
      level: 15,
      maxLevel: 15,
      category: 'active',
      traits: [
        { id: "SkillSet_WP_SW_SH_S_DebuffAttack_trait_1", name: "Increased Debuff Duration", description: "Increases the duration of the debuff" }
      ]
    },
    // ... (añade más habilidades aquí)
  ]);

  const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null);

  const weaponMap: WeaponMap = useMemo(() => ({
    sword: {
      name: "Sword",
      skills: ["SkillSet_WP_SW_SH_S_DebuffAttack", "SkillSet_WP_SW_SH_S_PullSkill", "SkillSet_WP_SW_SH_S_WhirlwindSlash", "SkillSet_WP_SW_SH_S_ShieldBuff", "SkillSet_WP_SW_SH_S_BuffAttack"]
    },
    sword2h: {
      name: "Two-Handed Sword",
      skills: ["SkillSet_WP_SW2_S_StunAttack", "SkillSet_WP_SW2_S_UpperAttack", "SkillSet_WP_SW2_S_CounterAttack", "SkillSet_WP_SW2_S_PowerAttack", "SkillSet_WP_SW2_S_GaiaCrash", "SkillSet_WP_SW2_S_LeapAttack", "SkillSet_WP_SW2_S_ShieldBuff"]
    },
    // Añade más armas según sea necesario
  }), []);

  const handleWeaponChange = useCallback((hand: 'mainHand' | 'offHand', weapon: string) => {
    setBuildData(prevBuild => ({
      ...prevBuild,
      [hand]: weapon
    }));
  }, []);

  const getAvailableSkills = useCallback(() => {
    const mainHandSkills = weaponMap[buildData.mainHand]?.skills || [];
    const offHandSkills = weaponMap[buildData.offHand]?.skills || [];
    return Array.from(new Set([...mainHandSkills, ...offHandSkills]));
  }, [buildData.mainHand, buildData.offHand, weaponMap]);

  const handleSkillClick = useCallback((skill: Skill) => {
    setSelectedSkill(skill);
  }, []);

  const handleLevelChange = useCallback((skillId: string, change: number) => {
    setBuildData(prevBuild => {
      const categories: ('active' | 'passive' | 'defensive')[] = ['active', 'passive', 'defensive'];
      for (const category of categories) {
        const updatedSkills = prevBuild[category].map(skill => {
          if (skill.skillId === skillId) {
            return { ...skill, lvl: Math.max(0, Math.min(15, skill.lvl + change)) };
          }
          return skill;
        });
        if (updatedSkills !== prevBuild[category]) {
          return { ...prevBuild, [category]: updatedSkills };
        }
      }
      return prevBuild;
    });
  }, []);

  const handleTraitToggle = useCallback((skillId: string, traitId: string) => {
    setBuildData(prevBuild => {
      const categories: ('active' | 'passive' | 'defensive')[] = ['active', 'passive', 'defensive'];
      for (const category of categories) {
        const updatedSkills = prevBuild[category].map(skill => {
          if (skill.skillId === skillId) {
            const traits = skill.traits || [];
            if (traits.includes(traitId)) {
              return { ...skill, traits: traits.filter(t => t !== traitId) };
            } else {
              return { ...skill, traits: [...traits, traitId] };
            }
          }
          return skill;
        });
        if (updatedSkills !== prevBuild[category]) {
          return { ...prevBuild, [category]: updatedSkills };
        }
      }
      return prevBuild;
    });
  }, []);

  const availableSkills = useMemo(() => getAvailableSkills(), [getAvailableSkills]);

  return (
    <div className="build-container">
      <h1>{buildData.name}</h1>
      <div className="weapons">
        <div>
          <label>Main Hand:</label>
          <select value={buildData.mainHand} onChange={(e) => handleWeaponChange('mainHand', e.target.value)}>
            {Object.entries(weaponMap).map(([key, value]) => (
              <option key={key} value={key}>{value.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Off Hand:</label>
          <select value={buildData.offHand} onChange={(e) => handleWeaponChange('offHand', e.target.value)}>
            {Object.entries(weaponMap).map(([key, value]) => (
              <option key={key} value={key}>{value.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="tags">
        {buildData.tags.map(tag => <span key={tag} className="tag">{tag}</span>)}
      </div>
      <div className="skill-categories">
        {['active', 'passive', 'defensive'].map(category => (
          <div key={category} className="skill-category">
            <h2>{category.charAt(0).toUpperCase() + category.slice(1)} Skills</h2>
            <div className="skill-grid">
              {(buildData[category as keyof BuildData] as SkillData[])
                .filter(skillData => availableSkills.includes(skillData.skillId))
                .map((skillData: SkillData) => {
                  const skill = skills.find(s => s.id === skillData.skillId);
                  return skill ? (
                    <div key={skill.id} className="skill-icon" onClick={() => handleSkillClick(skill)}>
                      <img src={skill.image} alt={skill.name} />
                      <span className="skill-level">{skillData.lvl}/{skill.maxLevel}</span>
                    </div>
                  ) : null;
                })}
            </div>
          </div>
        ))}
      </div>
      <div className="skill-details">
        {selectedSkill && (
          <>
            <h2>{selectedSkill.name}</h2>
            <p>{selectedSkill.description}</p>
            <div className="level-controls">
              <button onClick={() => handleLevelChange(selectedSkill.id, -1)}>-</button>
              <span>{buildData[selectedSkill.category].find(s => s.skillId === selectedSkill.id)?.lvl || 0}/{selectedSkill.maxLevel}</span>
              <button onClick={() => handleLevelChange(selectedSkill.id, 1)}>+</button>
            </div>
            <div className="traits">
              <h3>Traits</h3>
              {selectedSkill.traits.map(trait => (
                <div key={trait.id} className="trait">
                  <input
                    type="checkbox"
                    checked={buildData[selectedSkill.category].find(s => s.skillId === selectedSkill.id)?.traits?.includes(trait.id) || false}
                    onChange={() => handleTraitToggle(selectedSkill.id, trait.id)}
                  />
                  <span>{trait.name}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="build-description" dangerouslySetInnerHTML={{ __html: buildData.description }} />
    </div>
  );
};

export default Build;



