import React, { useState } from 'react';
import './Lottery.css';

interface Participant {
  name: string;
}

const Lottery: React.FC = () => {
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [winner, setWinner] = useState('');

  const addParticipant = (name: string) => {
    if (name.trim() !== '') {
      setParticipants((prevParticipants) => [...prevParticipants, { name }]);
    }
  };

  const spinWheel = () => {
    if (participants.length > 0) {
      const randomIndex = Math.floor(Math.random() * participants.length);
      setWinner(participants[randomIndex].name);
    }
  };

  return (
    <div className="lottery">
      <h1>Ruleta de sorteos</h1>
      <input
        type="text"
        placeholder="Agregar participante"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            addParticipant(e.currentTarget.value);
            e.currentTarget.value = '';
          }
        }}
      />
      <button onClick={spinWheel}>¡Sortear!</button>
      {winner && <p>El ganador es: {winner}</p>}
      <div className="wheel">
        {participants.map((participant, index) => (
          <div
            key={index}
            className="slice"
            style={{ '--angle': `${360 / participants.length}deg` } as React.CSSProperties}
          >
            <span>{participant.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Lottery;