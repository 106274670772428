import React, { useState, useEffect } from 'react';

interface Props {
  // Puedes agregar props adicionales aquí si los necesitas
}

const DigitalClock: React.FC<Props> = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timerID);
  }, []);

  return (
    <div>
      <h1>Hora actual:</h1>
      <p>{time.toLocaleTimeString()}</p>
    </div>
  );
};

export default DigitalClock;