import React, { useState } from 'react';
import './App.css';
import Inicio from './components/Inicio';
import Ruleta from './components/Ruleta';
import Build from './components/Build';
import DigitalClock from './components/reloj'; // Importa el componente DigitalClock
import Lottery from './components/Lottery'; // Importa el componente Lottery

function App() {
  const [currentPage, setCurrentPage] = useState('inicio');

  const renderContent = () => {
    switch(currentPage) {
      case 'inicio':
        return <Inicio />;
      case 'ruleta':
        return <Ruleta />;
      case 'acerca-de':
        return <h2>Acerca de nosotros</h2>;
      case 'builds':
        return <Build />;
      case 'lottery':
        return <Lottery />;
      case 'reloj':
        return <DigitalClock />; // Agrega el caso para DigitalClock
      default:
        return <h2>Página no encontrada</h2>;
    }
  };

  return (
    <div className="App">
      <nav className="sidebar">
        <ul>
          <li><a href="#" onClick={() => setCurrentPage('inicio')}>Inicio</a></li>
          <li><a href="#" onClick={() => setCurrentPage('ruleta')}>Ruleta</a></li>
          <li><a href="#" onClick={() => setCurrentPage('builds')}>Builds</a></li>
          <li><a href="#" onClick={() => setCurrentPage('lottery')}>Lotería</a></li>
          <li><a href="#" onClick={() => setCurrentPage('reloj')}>Reloj</a></li> {/* Agrega la opción de Reloj */}
          <li><a href="#" onClick={() => setCurrentPage('acerca-de')}>Acerca de</a></li>
        </ul>
      </nav>
      <main className="content">
        {renderContent()}
      </main>
    </div>
  );
}

export default App;
