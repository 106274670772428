import React, { useState, useRef, useEffect } from 'react';
import './Ruleta.css';

const Ruleta: React.FC = () => {
  const [nombres, setNombres] = useState<string[]>(['Ali', 'Beatriz', 'Charles', 'Diya', 'Eric', 'Fatima', 'Gabriel', 'Hanna']);
  const [girando, setGirando] = useState(false);
  const [resultados, setResultados] = useState<string[]>([]);
  const [tabActiva, setTabActiva] = useState('entradas');
  const ruletaRef = useRef<HTMLDivElement>(null);

  const generarColor = (index: number, total: number) => {
    const hue = (index / total) * 360;
    return `hsl(${hue}, 70%, 50%)`;
  };

  const girarRuleta = () => {
    if (!girando && nombres.length > 0) {
      setGirando(true);
      const grados = 360 * 10 + Math.floor(Math.random() * 360); // 10 giros completos + giro aleatorio
      if (ruletaRef.current) {
        ruletaRef.current.style.transition = 'transform 5s linear';
        ruletaRef.current.style.transform = `rotate(${grados}deg)`;
      }
      setTimeout(() => {
        setGirando(false);
        const ganador = nombres[Math.floor(Math.random() * nombres.length)];
        setResultados(prevResultados => [ganador, ...prevResultados]);
        setTabActiva('resultados');
      }, 5000);
    }
  };

  const barajar = () => {
    setNombres([...nombres].sort(() => Math.random() - 0.5));
  };

  const ordenar = () => {
    setNombres([...nombres].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })));
  };

  const agregarImagen = () => {
    // Implementación pendiente
    console.log('Función de agregar imagen no implementada');
  };

  const actualizarNombres = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const nuevosNombres = e.target.value.split('\n').filter(n => n.trim() !== '');
    setNombres(nuevosNombres);
  };

  useEffect(() => {
    if (ruletaRef.current) {
      ruletaRef.current.style.transition = 'none';
      ruletaRef.current.style.transform = 'rotate(0deg)';
    }
  }, [nombres]);

  return (
    <div className="ruleta-container">
      <div className="panel-control">
        <div className="tabs">
          <div 
            className={`tab ${tabActiva === 'entradas' ? 'active' : ''}`}
            onClick={() => setTabActiva('entradas')}
          >
            Entradas <span className="count">{nombres.length}</span>
          </div>
          <div 
            className={`tab ${tabActiva === 'resultados' ? 'active' : ''}`}
            onClick={() => setTabActiva('resultados')}
          >
            Resultados <span className="count">{resultados.length}</span>
          </div>
        </div>
        <div className="options">
          <button onClick={barajar}>Barajar</button>
          <button onClick={ordenar}>Ordenar</button>
          <button onClick={agregarImagen}>Agregar imagen</button>
          <label><input type="checkbox" /> Avanzado</label>
        </div>
        {tabActiva === 'entradas' ? (
          <textarea
            value={nombres.join('\n')}
            onChange={actualizarNombres}
            placeholder="Ingresa los nombres (uno por línea)"
            rows={10}
          />
        ) : (
          <ul className="resultados-lista">
            {resultados.map((resultado, index) => (
              <li key={index}>{resultado}</li>
            ))}
          </ul>
        )}
      </div>
      <div className="ruleta-wrapper">
        <div ref={ruletaRef} className="ruleta" onClick={girarRuleta}>
          {nombres.map((nombre, index) => (
            <div
              key={index}
              className="segmento"
              style={{
                '--index': index,
                '--total': nombres.length,
                '--color': generarColor(index, nombres.length)
              } as React.CSSProperties}
            >
              <span className="nombre">{nombre}</span>
            </div>
          ))}
        </div>
        <div className="centro"></div>
        <div className="flecha"></div>
      </div>
    </div>
  );
};

export default Ruleta;
